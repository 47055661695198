import {
  Ad,
  GamePlatformEnum,
  AdPlatformEnum,
  ValueAddTypeEnum,
  PlatformOsEnum,
  InsertionOrderSourceEnum,
  BillingSourceEnum,
  InvoicingSourceEnum,
  InsertionOrderCurrencyEnum,
} from '../../../__generated__/typedefs'
import {
  AD_CONTENT_TYPE,
  AD_CAMPAIGN_TYPE,
  AD_PURPOSE,
  AD_STATUS,
  // BID_TYPE,
  PACING,
} from '../constants.js'
import { Genders } from '../enums'
import * as tagsLib from '@shared/tag'
import { formatNumber, abbrNumber, parseNumWithCommas } from '@utils'
import { html } from '@shared/string/intellisense'
import { OrgGetters } from '@state/modules/org.store'

type AdKeys = keyof Omit<Ad, '__typename'>
type AdKeysSet = Array<AdKeys>

export const ORDER_TAB_INDEX = 1

export const STATE_KEY = {
  AD_CAMPAIGN_TYPE: 'campaignType',
  NICKNAME: 'nickname',
  CLIENT: 'client',
  ORG: 'org',
  LOCATIONS: 'locations',
  DMAS: 'DMAs',
  MAGNITE_SEAT_ID: 'magniteSeatID', // todo: rename magnite. this is legacy naming before ad platform selection was introduced
  MAGNITE_DEAL_ID: 'magniteDealID',
  AD_PLATFORM: 'adPlatform',
  KOCHAVA: 'kochava',
  GAME_IDS: 'gameIDs',
  GENRE_OPT_IN: 'genres',
  GENRE_OPT_OUT: 'genresOptOut',
  OS: 'os',
  BUDGET: 'budgetTotal',
  DAILY_SPEND_LIMIT: 'budgetDaily',
  DISPLAY_START_DATE: 'displayStartDate',
  DISPLAY_END_DATE: 'displayEndDate',
  COST_PER_IMPRESSION: 'costPerImpression',
  PACING: 'pacing',
  BID_TYPE: 'bidType',
  MIN_IMPRESSIONS: 'minImpressions',
  IMPRESSION_URLS: 'impressionURLs',
  CPM_PRICE: 'CPMID',
  FREQUENCY_CAPS: 'frequencyCaps',
  TIME_OF_DAYS: 'timeOfDays',
  TAGS: 'tags',
  TIME_ZONE: 'timezone',
  GOOGLE_PARTNER_TARGETING: 'partnerTargeting',
  GAM: 'gam',
  CONTENT_TYPE: 'contentType',
  CPM_CENTS: 'CPMCents',
  SHARE_OF_VOICE: 'shareOfVoice',
  EXTERNALLY_HOSTED: 'externallyHosted',

  // IO
  INSERTION_ORDER_SOURCE: 'insertionOrderSource',
  OPPORTUNITY_ID: 'opportunityID',
  PURCHASE_ORDER_NUMBER: 'purchaseOrderNumber',
  AD_TRAFFICKER: 'adTraffickerContactEmail',
  BILLING_SOURCE: 'billingSource',
  INVOICING_SOURCE: 'invoicingSource',
  CURRENCY: 'currency',
  CLIENT_BUDGET: 'clientBudget',

  THIRD_PARTY_SETTINGS: 'thirdPartySettings',
  BRAND_LIFT_STUDIES: 'brandLiftStudies',
  AUDIENCE_SEGMENTS: 'audienceSegments',
}

export const createAdInputKeys: AdKeysSet = [
  'campaignType',
  'purpose',
  'valueAddType',
  'title',
  'nickname',
  'client',
  'gameIDs',
  'genres',
  'genresOptOut',
  'platforms',
  'os',
  'kochava',
  'locations',
  'DMAs',
  'shareOfVoice',
  'languages',
  'genders',
  'minAge',
  'maxAge',
  'ratings',
  'pacing',
  'adRestrictions',
  'acceptTerms',
  'coppaCompliant',
  'budgetTotal',
  'budgetDaily',
  'bidType',
  'adCreatives',
  'impressionURLs',
  'frequencyCaps',
  'timeOfDays',
  'magnite',
  'tags',
  'timezone',
  'displayStartDate',
  'displayEndDate',
  'CPMCents',
  'externallyHosted',
]

export const cloneAdInputKeys = {
  AD_CAMPAIGN_TYPE: 'campaignType',
  PURPOSE: 'purpose',
  VALUE_ADD_TYPE: 'valueAddType',
  TITLE: 'title',
  NICKNAME: 'nickname',
  CLIENT: 'client',
  GENRES: 'genres',
  GENRES_OPT_OUT: 'genresOptOut',
  PLATFORMS: 'platforms',
  KOCHAVA: 'kochava',
  LOCATIONS: 'locations',
  DMA: 'DMAs',
  LANGUAGES: 'languages',
  GENDERS: 'genders',
  MIN_AGE: 'minAge',
  MAX_AGE: 'maxAge',
  RATINGS: 'ratings',
  PACING: 'pacing',
  AD_RESTRICTIONS: 'adRestrictions',
  COPPA_COMPLIANT: 'coppaCompliant',
  BUDGET_TOTAL: 'budgetTotal',
  BUDGET_DAILY: 'budgetDaily',
  BID_TYPE: 'bidType',
  AD_CREATIVES: 'adCreatives',
  IMPRESSION_URLS: 'impressionURLs',
  CPMID: 'CPMID',
  FREQUENCY_CAPS: 'frequencyCaps',
  TIME_OF_DAYS: 'timeOfDays',
  MAGNITE: 'magnite',
  TAGS: 'tags',
  TIMEZONE: 'timezone',
  DISPLAY_START_DATE: 'displayStartDate',
  DISPLAY_END_DATE: 'displayEndDate',
  EXTERNALLY_HOSTED: 'externallyHosted',
  INSERTION_ORDER_DETAILS: 'insertionOrderDetails',
}

export const frequencyCapDurations = [
  {
    text: 'Hour',
    value: '3600',
    disabled: false,
  },
  {
    text: 'Day',
    value: '86400',
    disabled: false,
  },
  {
    text: 'Week',
    value: '604800',
    disabled: false,
  },
  {
    text: 'Month',
    value: '18144000',
    disabled: false,
  },
  {
    text: 'Lifetime',
    value: '999999999',
    disabled: false,
  },
]

export const updateAdInputKeys: AdKeysSet = [...createAdInputKeys, 'id']

export const VALUE_TYPES = {
  MONEY: 'money',
  CENTS: 'cents',
  DATE: 'date',
  IMAGE: 'image',
  SELECTION: 'selection',
}

export const MSG_START_TIME_REQUIRED = 'Start time is required'
export const MSG_END_TIME_REQUIRED = 'End time is required'

export const CPM_EXPLANATION = html`
  <div>
    <strong>CPM</strong> or <strong>Cost Per Mile</strong>
    bidding represents the maximum dollar amount that you pay per 1,000
    advertisement impressions on the Frameplay platform.
  </div>
`

export const HOSTED_CREATIVE_EXPLANATION =
  'Hosted creatives are stored and validated by Frameplay.'

export const MAX_BID = 99999.0
export const MIN_BID = 25.0
export const MAX_BUDGET = 999999999.99
export const NON_FPSA_AND_SELF_APPROVE_MIN_BUDGET = 1000.0
export const FPSA_AND_SELF_APPROVE_MIN_BUDGET = 0.01
export const MAX_DAILY_SPEND_LIMIT = 50000
export const MIN_DAILY_SPEND_LIMIT = 30

export const MIN_CPM_NON_SA = 1.0
export const MIN_CPM_SA_AND_SELF_APPROVE = 0.01
export const MAX_CPM_NON_SA = 600
export const MAX_CPM_SA = 600

export const DEFAULT_MIN_AGE = 13
export const DEFAULT_MAX_AGE = 120
export const DEFAULT_DAILY_BUDGET = '0.00'
export const GEN_DEFAULT_AD_RESTRICTIONS = () => []
export const GEN_DEFAULT_FREQUENCY_CAPS = () => [{ max: null, duration: null }]
export const GEN_DEFAULT_TIME_OF_DAYS = () => [{ start: '', end: '' }]
export const GEN_DEFAULT_TAGS = () => [
  { type: tagsLib.INTERNAL_KEY, items: [] },
]
export const GEN_DEFAULT_KOCHAVA = () => ''

export const INPUT_LABELS = {
  kochavaMeasurementID: 'Measurement ID',
}

// MARK: Labels
export const LABELS = {
  [STATE_KEY.AD_CAMPAIGN_TYPE]: 'Campaign type',
  purpose: 'What is your goal?',
  valueAddType: 'Value add type',
  title: 'Advertisement name',
  [STATE_KEY.NICKNAME]: 'Advertisement nickname',
  org: 'Organization',
  client: 'Client',
  magniteSeatID: 'Seat ID',
  magniteDealID: 'Deal ID',
  adPlatform: 'Ad Platform (requires Deal ID)',
  kochava: 'Kochava',
  [STATE_KEY.PACING]: 'Pacing',
  [STATE_KEY.DISPLAY_START_DATE]: 'Start time',
  [STATE_KEY.DISPLAY_END_DATE]: 'End time',
  impressionURLs: 'Impression tag URLs',
  [STATE_KEY.TIME_ZONE]: 'Time zone',
  [STATE_KEY.GAME_IDS]: 'Game Titles',
  [STATE_KEY.GENRE_OPT_IN]: 'Game genres opt-in',
  [STATE_KEY.GENRE_OPT_OUT]: 'Game genres opt-out',
  platforms: 'Game platforms',
  os: 'Game operating systems',
  platformsAndOs: 'Game platforms and operating systems',
  ratings: 'Game ratings',
  locations: 'Locations',
  DMAs: 'DMA targeting',
  languages: 'Languages',
  genders: 'Genders',
  includeAgeRange: 'Include age range',
  minAge: 'Minimum age',
  maxAge: 'Maximum age',
  coppaCompliant: 'Is this ad COPPA compliant?',
  hostedCreative: 'Use Frameplay hosted creative?',

  budgetTotal: 'Total ad budget',
  hasDailySpendLimit: 'Enable daily spend limit?',
  budgetDaily: 'Daily ad budget',
  bidType: 'Pricing model',
  cpmPrice: 'CPM price',
  frequencyCaps: 'Frequency caps',
  timeOfDays: 'Dayparting',
  costPerImpression: 'Max. cost per impression',
  minImpressions: 'Goal (Viewable Impressions)',

  contentType: 'Creative type',
  adRestrictions: 'Restricted content descriptors',

  adCreatives: 'Creative assets',
  [STATE_KEY.TAGS]: 'Meta tags',

  partnerTargeting: 'Partner Targeting',
  googlePartnerRef: 'Partner References',

  cpmCents: 'CPM rate',
  [STATE_KEY.SHARE_OF_VOICE]: 'Share of Voice Goal (%)',
  gameIDs: 'Game titles',

  // insertion orders
  [STATE_KEY.INSERTION_ORDER_SOURCE]: 'Buy type',
  [STATE_KEY.OPPORTUNITY_ID]: 'Salesforce ID',
  [STATE_KEY.PURCHASE_ORDER_NUMBER]: 'IO number',
  [STATE_KEY.AD_TRAFFICKER]: 'Ad trafficker contact email',
  [STATE_KEY.BILLING_SOURCE]: 'Billing type',
  [STATE_KEY.INVOICING_SOURCE]: 'Invoicing source',
  [STATE_KEY.CURRENCY]: 'IO currency',
  [STATE_KEY.CLIENT_BUDGET]: 'Client IO budget',

  [STATE_KEY.THIRD_PARTY_SETTINGS]: 'Third party impressions count',
  [STATE_KEY.BRAND_LIFT_STUDIES]: 'Brand lift study',
  [STATE_KEY.AUDIENCE_SEGMENTS]: 'Audience segments',
}

// MARK: Order
export const order = [
  {
    stateKey: STATE_KEY.INSERTION_ORDER_SOURCE,
    label: LABELS[STATE_KEY.INSERTION_ORDER_SOURCE],
    stateValue: InsertionOrderSourceEnum.AGENCY_ADVERTISER,
    valueType: VALUE_TYPES.SELECTION,
    condition: ({ insertionOrderVisible }) => {
      return insertionOrderVisible
    },
  },
  {
    stateKey: STATE_KEY.OPPORTUNITY_ID,
    label: LABELS[STATE_KEY.OPPORTUNITY_ID],
    stateValue: '',
    condition: ({ insertionOrderVisible, form }) => {
      return (
        insertionOrderVisible &&
        form?.insertionOrderDetails?.[STATE_KEY.INSERTION_ORDER_SOURCE]
      )
    },
  },
  {
    stateKey: STATE_KEY.PURCHASE_ORDER_NUMBER,
    label: LABELS[STATE_KEY.PURCHASE_ORDER_NUMBER],
    stateValue: '',
    condition: ({ insertionOrderVisible, form }) => {
      return (
        insertionOrderVisible &&
        form?.insertionOrderDetails?.[STATE_KEY.INSERTION_ORDER_SOURCE]
      )
    },
  },
  {
    stateKey: STATE_KEY.AD_TRAFFICKER,
    label: LABELS[STATE_KEY.AD_TRAFFICKER],
    stateValue: '',
    condition: ({ insertionOrderVisible, form }) => {
      return (
        insertionOrderVisible &&
        form?.insertionOrderDetails?.[STATE_KEY.INSERTION_ORDER_SOURCE]
      )
    },
  },
  {
    stateKey: STATE_KEY.BILLING_SOURCE,
    label: LABELS[STATE_KEY.BILLING_SOURCE],
    valueType: VALUE_TYPES.SELECTION,
    condition: ({ insertionOrderVisible, form }) => {
      return (
        insertionOrderVisible &&
        form?.insertionOrderDetails?.[STATE_KEY.INSERTION_ORDER_SOURCE]
      )
    },
  },
  {
    stateKey: STATE_KEY.INVOICING_SOURCE,
    label: LABELS[STATE_KEY.INVOICING_SOURCE],
    stateValue: InvoicingSourceEnum.INVOICE,
    valueType: VALUE_TYPES.SELECTION,
    condition: ({ insertionOrderVisible, form }) => {
      return (
        insertionOrderVisible &&
        form?.insertionOrderDetails?.[STATE_KEY.INSERTION_ORDER_SOURCE]
      )
    },
  },
  {
    stateKey: STATE_KEY.CURRENCY,
    label: LABELS[STATE_KEY.CURRENCY],
    stateValue: InsertionOrderCurrencyEnum.USD,
    condition: ({ insertionOrderVisible, form }) => {
      return (
        insertionOrderVisible &&
        form?.insertionOrderDetails?.[STATE_KEY.INSERTION_ORDER_SOURCE]
      )
    },
  },
  {
    stateKey: STATE_KEY.CLIENT_BUDGET,
    label: LABELS[STATE_KEY.CLIENT_BUDGET],
    stateValue: '0.00',
    valueType: VALUE_TYPES.MONEY,
    condition: ({ insertionOrderVisible, form }) => {
      return (
        insertionOrderVisible &&
        form?.insertionOrderDetails?.[STATE_KEY.INSERTION_ORDER_SOURCE]
      )
    },
  },
  {
    stateKey: STATE_KEY.THIRD_PARTY_SETTINGS,
    label: LABELS[STATE_KEY.THIRD_PARTY_SETTINGS],
    stateValue: [],
    condition: ({ insertionOrderVisible, form }) => {
      return (
        insertionOrderVisible &&
        form?.insertionOrderDetails?.[STATE_KEY.INSERTION_ORDER_SOURCE]
      )
    },
  },
  {
    stateKey: STATE_KEY.BRAND_LIFT_STUDIES,
    label: LABELS[STATE_KEY.BRAND_LIFT_STUDIES],
    stateValue: [],
    condition: ({ insertionOrderVisible, form }) => {
      return (
        insertionOrderVisible &&
        form?.insertionOrderDetails?.[STATE_KEY.INSERTION_ORDER_SOURCE]
      )
    },
  },
  {
    stateKey: STATE_KEY.AUDIENCE_SEGMENTS,
    label: LABELS[STATE_KEY.AUDIENCE_SEGMENTS],
    stateValue: [],
    condition: ({ insertionOrderVisible, form }) => {
      return (
        insertionOrderVisible &&
        form?.insertionOrderDetails?.[STATE_KEY.INSERTION_ORDER_SOURCE]
      )
    },
  },
]

export const orderKeys = order.map(conf => conf.stateKey)

// MARK: Overview
export const overview = [
  {
    stateKey: 'purpose',
    label: LABELS.purpose,
    stateValue: AD_PURPOSE.AWARENESS,
  },
  {
    stateKey: STATE_KEY.AD_CAMPAIGN_TYPE,
    label: LABELS[STATE_KEY.AD_CAMPAIGN_TYPE],
    stateValue: AD_CAMPAIGN_TYPE.STANDARD,
  },
  {
    stateKey: 'valueAddType',
    label: LABELS.valueAddType,
    stateValue: ValueAddTypeEnum.CLIENT_PAID,
    condition: ({ isSuperAdmin }) => {
      return isSuperAdmin
    },
  },
  { stateKey: 'title', label: LABELS.title, stateValue: '' },
  {
    stateKey: 'nickname',
    label: LABELS.nickname,
    stateValue: '',
    condition: ({ isSuperAdmin }) => {
      return isSuperAdmin
    },
  },
  {
    stateKey: STATE_KEY.ORG,
    label: LABELS.org,
    stateValue: '',
    condition: ({ orgVisible }) => {
      return orgVisible
    },
  },
  {
    stateKey: STATE_KEY.CLIENT,
    label: LABELS.client,
    stateValue: '',
    condition: ({ clientVisible }) => {
      return clientVisible
    },
  },

  {
    stateKey: STATE_KEY.AD_PLATFORM,
    label: LABELS.adPlatform,
    stateValue: AdPlatformEnum.NONE,
    condition: ({ adPlatformsVisible }) => {
      return adPlatformsVisible
    },
  },
  {
    stateKey: STATE_KEY.GOOGLE_PARTNER_TARGETING,
    label: LABELS.partnerTargeting,
    stateValue: '',
    condition: ({ isSuperAdmin, isGAM }) => {
      return isSuperAdmin && isGAM
    },
  },
  {
    stateKey: STATE_KEY.GAM,
    label: LABELS.googlePartnerRef,
    stateValue: {
      lineItemID: '',
      lineItemName: '',
      lineItemURL: '',
      orderItemID: '',
      orderItemName: '',
      orderItemURL: '',
    },
    condition: ({ isSuperAdmin, form }) =>
      isSuperAdmin && form?.[STATE_KEY.AD_PLATFORM] === AdPlatformEnum.GOOGLE,
  },
  {
    stateKey: STATE_KEY.KOCHAVA,
    label: LABELS.kochava,
    stateValue: '',
    condition: ({ isSuperAdmin }) => {
      return isSuperAdmin
    },
  },
  {
    stateKey: STATE_KEY.MAGNITE_DEAL_ID,
    label: LABELS.magniteDealID,
    stateValue: '',
    condition: ({ adPlatformsVisible, isGAM }) => {
      return adPlatformsVisible && !isGAM
    },
  },
  {
    stateKey: STATE_KEY.MAGNITE_SEAT_ID,
    label: LABELS.magniteSeatID,
    stateValue: '',
    condition: ({ seatIDInvisible, isGAM }) => {
      return seatIDInvisible && !isGAM
    },
  },
  {
    stateKey: STATE_KEY.DISPLAY_START_DATE,
    stateValue: '',
    label: LABELS[STATE_KEY.DISPLAY_START_DATE],
  },
  {
    stateKey: STATE_KEY.DISPLAY_END_DATE,
    stateValue: '',
    label: LABELS[STATE_KEY.DISPLAY_END_DATE],
  },
  {
    stateKey: STATE_KEY.TIME_ZONE,
    stateValue: '',
    label: LABELS[STATE_KEY.TIME_ZONE],
  },
  { stateKey: 'impressionURLs', label: LABELS.impressionURLs, stateValue: [] },
]

export const overviewkeys = overview.map(conf => conf.stateKey)

export enum LocationTypes {
  GEO = 'GEO',
  DMA = 'DMA',
}

export enum DMAIDs {
  ALL = '1',
}

// MARK: Audience
export const audience = [
  {
    stateKey: STATE_KEY.GAME_IDS,
    label: LABELS[STATE_KEY.GAME_IDS],
    stateValue: [],
  },
  {
    stateKey: 'ratings',
    label: LABELS.ratings,
    stateValue: [],
    condition: ({ isSuperAdmin }) => {
      return isSuperAdmin
    },
  },
  {
    stateKey: STATE_KEY.GENRE_OPT_IN,
    label: LABELS[STATE_KEY.GENRE_OPT_IN],
    stateValue: '',
  },
  {
    stateKey: STATE_KEY.GENRE_OPT_OUT,
    label: LABELS[STATE_KEY.GENRE_OPT_OUT],
    stateValue: '',
  },
  {
    stateKey: 'platforms',
    label: LABELS.platforms,
    stateValue: [GamePlatformEnum.PC, GamePlatformEnum.MOBILE],
  },
  {
    stateKey: 'os',
    label: LABELS.os,
    stateValue: [
      PlatformOsEnum.WINDOWS,
      PlatformOsEnum.OSX,
      PlatformOsEnum.ANDROID,
      PlatformOsEnum.IOS,
    ],
  },
  {
    stateKey: STATE_KEY.LOCATIONS,
    label: LABELS.locations,
    stateValue: [],
    condition: ({ form }) => {
      return !(form.DMAs && form.DMAs.length)
    },
  },
  {
    stateKey: 'DMAs',
    label: LABELS.DMAs,
    stateValue: [],
    condition: ({ form }) => {
      return form.DMAs && form.DMAs.length
    },
  },
  {
    stateKey: 'locationType',
    stateValue: LocationTypes.GEO,
    condition: () => false,
  },
  {
    stateKey: 'shareOfVoice',
    label: LABELS[STATE_KEY.SHARE_OF_VOICE],
    stateValue: 0,
    condition: ({ form }) => {
      return form[STATE_KEY.AD_CAMPAIGN_TYPE] === AD_CAMPAIGN_TYPE.SPONSORSHIP
    },
  },
  { stateKey: 'languages', label: LABELS.languages, stateValue: [] },
  {
    stateKey: 'genders',
    label: LABELS.genders,
    stateValue: Genders[2],
    // ⬇⬇ hide age and genders field
    // https://lowlatencymedia.atlassian.net/browse/PR-3634
    condition: () => false,
  },
  {
    stateKey: 'includeAgeRange',
    label: LABELS.includeAgeRange,
    stateValue: false,
    // condition: ({ form }) => {
    //   const noValue = !form.minAge && !form.maxAge
    //   const isDefault =
    //     form.minAge === DEFAULT_MIN_AGE && form.maxAge === DEFAULT_MAX_AGE

    //   return noValue || isDefault
    // },
    condition: () => false,
  },
  {
    stateKey: 'minAge',
    label: LABELS.minAge,
    stateValue: '',
    condition: ({ form }) => !!form.minAge,
  },
  {
    stateKey: 'maxAge',
    label: LABELS.maxAge,
    stateValue: '',
    condition: ({ form }) => !!form.maxAge,
  },
  {
    stateKey: 'coppaCompliant',
    label: LABELS.coppaCompliant,
    stateValue: false,
  },
  // show tags before TOD, as a way to prevent users clicking next before blurring out a meta tag
  // to prevent a row with a type with no tags. we still validate, this is an extra way to prevent it
  {
    stateKey: STATE_KEY.TAGS,
    label: LABELS[STATE_KEY.TAGS],
    stateValue: [],
    condition: ({ isSuperAdmin }) => {
      return isSuperAdmin
    },
  },
  {
    stateKey: STATE_KEY.TIME_OF_DAYS,
    label: LABELS.timeOfDays,
    stateValue: [],
  },
]

export const audiencekeys = audience.map(conf => conf.stateKey)

export const budget = [
  {
    stateKey: STATE_KEY.BUDGET,
    stateValue: FPSA_AND_SELF_APPROVE_MIN_BUDGET,
    label: LABELS.budgetTotal,
    valueType: VALUE_TYPES.MONEY,
    condition: ({ isSuperAdmin }) => {
      return isSuperAdmin
    },
  },
  {
    stateKey: STATE_KEY.BUDGET,
    stateValue: NON_FPSA_AND_SELF_APPROVE_MIN_BUDGET,
    label: LABELS.budgetTotal,
    valueType: VALUE_TYPES.MONEY,
    condition: ({ isSelfApprove }) => {
      return isSelfApprove
    },
  },
  // TODO add new cpm here
  {
    stateKey: STATE_KEY.CPM_CENTS,
    stateValue: '10.00',
    label: LABELS.cpmCents,
    valueType: VALUE_TYPES.MONEY,
  },
  {
    stateKey: STATE_KEY.PACING,
    label: LABELS.pacing,
    stateValue: PACING.EVEN,
    condition: ({ isSuperAdmin }) => {
      return isSuperAdmin
    },
  },
  {
    stateKey: 'hasDailySpendLimit',
    label: LABELS.hasDailySpendLimit,
    stateValue: false,
    condition: ({ form }) => !form.budgetDaily,
  },
  {
    stateKey: STATE_KEY.DAILY_SPEND_LIMIT,
    stateValue: '0.00',
    label: LABELS.budgetDaily,
    valueType: VALUE_TYPES.MONEY,
    condition: ({ form }) => !!form.budgetDaily,
  },
  {
    stateKey: STATE_KEY.FREQUENCY_CAPS,
    label: LABELS.frequencyCaps,
    stateValue: [
      {
        max: null,
        duration: null,
      },
    ],
  },
  // {
  //   stateKey: STATE_KEY.BID_TYPE,
  //   label: LABELS.bidType,
  //   stateValue: BID_TYPE.COST_PER_IMPRESSION,
  // },
  {
    stateKey: STATE_KEY.MIN_IMPRESSIONS,
    stateValue: 0,
    label: LABELS.minImpressions,
    valueType: VALUE_TYPES.MONEY,
  },
]

export const budgetkeys = budget.map(conf => conf.stateKey)

// MARK: Upload
export const upload = [
  {
    stateKey: 'contentType',
    label: LABELS.contentType,
    stateValue: AD_CONTENT_TYPE.IMAGE,
  },
  {
    stateKey: 'adRestrictions',
    label: LABELS.adRestrictions,
    stateValue: [],
    condition: ({ form }) => form.adRestrictions.length,
  },
  {
    stateKey: 'adCreatives',
    label: LABELS.adCreatives,
    stateValue: [],
    valueType: VALUE_TYPES.IMAGE,
  },
  {
    stateKey: STATE_KEY.EXTERNALLY_HOSTED,
    label: LABELS.hostedCreative,
    stateValue: false,
    condition: ({ isSuperAdmin }) => {
      return isSuperAdmin
    },
  },
]

export const uploadkeys = upload.map(conf => conf.stateKey)

export const adFormGroups = {
  order,
  overview,
  audience,
  budget,
  upload,
}

export const genInitialAdForm = (groups: typeof adFormGroups): any => {
  if (!groups) return {}

  let state = {}

  Object.keys(groups).forEach(key => {
    groups[key].forEach(item => {
      state[item.stateKey] = item.stateValue
    })
  })

  return state
}

export const genInitialInsertionOrderDetailsForm = () => {
  let state: { [index: string]: any } = {}

  order.forEach(item => {
    state[item.stateKey] = item.stateValue
  })

  return state
}

export const initialAdForm = {
  id: '',
  showBrandLiftStudies: false,
  showAudienceSegments: false,
  ...genInitialAdForm(adFormGroups),
}

export const getAdStatusChipType = value => {
  let type

  switch (value) {
    case AD_STATUS.ARCHIVED:
      type = 'disabled'
      break
    case AD_STATUS.DRAFT:
      type = 'info'
      break
    case AD_STATUS.PENDING:
      type = 'warning'
      break
    case AD_STATUS.REJECTED:
      type = 'error'
      break
    case AD_STATUS.ACTIVE:
    case AD_STATUS.EXTENDED:
    case AD_STATUS.APPROVED:
      type = 'success'
      break
    case AD_STATUS.PAUSED:
      type = 'accent'
      break
    default:
      type = 'inactive'
  }

  return type
}

export const getGoalImpressions = ({
  budget,
  cpm,
  isSuperAdmin,
  discount = 0,
}: {
  budget: string
  cpm: number | string
  isSuperAdmin: boolean
  discount?: number | string
}) => {
  const parsedBudget = Number.parseFloat(budget)
  const parsedCPM = Number.parseFloat(`${cpm}`)
  const parsedDiscount = Number.parseFloat(`${discount || 0}`)

  let impressions = 0

  const isSelfApprove = OrgGetters('isSelfApprove')
  const minBudget =
    isSuperAdmin || isSelfApprove
      ? FPSA_AND_SELF_APPROVE_MIN_BUDGET
      : NON_FPSA_AND_SELF_APPROVE_MIN_BUDGET
  const minCPM =
    isSuperAdmin || isSelfApprove ? MIN_CPM_SA_AND_SELF_APPROVE : MIN_CPM_NON_SA
  const maxCPM = isSuperAdmin ? MAX_CPM_SA : MAX_CPM_NON_SA

  if (
    parsedBudget < minBudget ||
    parsedBudget > MAX_BUDGET ||
    parsedCPM < minCPM ||
    parsedCPM > maxCPM
  ) {
    return '-'
  }

  impressions = Math.ceil(((parsedBudget + parsedDiscount) / parsedCPM) * 1000)

  if (isNaN(impressions) || impressions === Infinity) return '-'

  return impressions > 1000000000
    ? abbrNumber(impressions)
    : formatNumber(impressions)
}

export const getCPM = ({
  budget,
  impressions,
}: {
  budget: number | string
  impressions: number | string
}) => {
  const formatImpressions =
    typeof impressions === 'string'
      ? parseNumWithCommas(impressions)
      : impressions
  const parsedBudget = Number.parseFloat(`${budget || 0}`)
  const parsedimpressions = Number.parseInt(`${formatImpressions || 1}`)

  let cpm = '0.00'
  cpm = ((parsedBudget / parsedimpressions) * 1000).toFixed(2)

  return cpm
}

export const formatInsertionOrderDetails = ({ form, orgID }) => {
  let tempForm: { [index: string]: any } = {
    orgID,
  }
  if (form.insertionOrderSource === InsertionOrderSourceEnum.OPEN_MARKET) {
    tempForm = { ...tempForm, ...genInitialInsertionOrderDetailsForm() }
  } else {
    orderKeys.forEach(key => {
      if (!form[key]) {
        form[key] = order.find(
          orderItem => orderItem.stateKey === key
        )?.stateValue
      }
      if (
        (key === STATE_KEY.AUDIENCE_SEGMENTS && !form.showAudienceSegments) ||
        (key === STATE_KEY.BRAND_LIFT_STUDIES && !form.showBrandLiftStudies) ||
        (key === STATE_KEY.THIRD_PARTY_SETTINGS &&
          form[STATE_KEY.BILLING_SOURCE] !== BillingSourceEnum.THIRD_PARTY)
      ) {
        return
      }

      tempForm[key] = form[key]

      // __typename is for GraphQL memory caching, so we remove it here instead of GraphQL config
      if (
        key === STATE_KEY.AUDIENCE_SEGMENTS ||
        key === STATE_KEY.BRAND_LIFT_STUDIES ||
        key === STATE_KEY.THIRD_PARTY_SETTINGS
      ) {
        tempForm[key].forEach(item => {
          delete item['__typename']
        })
      }

      if (key === STATE_KEY.THIRD_PARTY_SETTINGS) {
        tempForm[key].forEach(item => {
          item.impressionsCount = parseInt(item.impressionsCount)
        })
      }
    })
  }

  return { ...tempForm }
}
