import * as tsTypes from '@graphql-types'

const EARLY_CHILDHOOD = require('../assets/images/game-rating/esrb-ec.svg')
const EVERYONE = require('../assets/images/game-rating/esrb-e.svg')
const EVERYONE_TEN_PLUS = require('../assets/images/game-rating/esrb-e10.svg')
const TEEN = require('../assets/images/game-rating/esrb-t.svg')
const MATURE = require('../assets/images/game-rating/esrb-m.svg')
const ADULTS_ONLY = require('../assets/images/game-rating/esrb-ao.svg')
const RATING_PENDING = require('../assets/images/game-rating/esrb-rp.svg')

// ENUMS
export const AdSpaceStatus = ['ACTIVE', 'ARCHIVED', 'PAUSED', 'UNKNOWN']

export const AdStatus = [
  'DRAFT',
  'ARCHIVED',
  'PENDING',
  'REJECTED',
  'APPROVED',
  'ACTIVE',
  'EXPIRED',
  'EXTENDED',
  'PAUSED',
  'UNKNOWN',
]

export const GameStatus = [
  'DRAFT',
  'ACTIVE',
  'ARCHIVED',
  'PENDING',
  'REJECTED',
  'PAUSED',
  'APPROVED',
  'UNKNOWN',
]

export const ReportLastStatus = [
  'IN_PROGRESS',
  'FAILED',
  'SUCCESSFUL',
  'SCHEDULED',
]

export const DefaultOrderByEnum = [
  'TITLE_ASC',
  'TITLE_DESC',
  'DATE_CREATED_ASC',
  'DATE_CREATED_DESC',
]

export const AdPurposeEnum = ['AWARENESS', 'SALES']

export const CampaignTypeEnum = ['STANDARD', 'SPONSORSHIP']

// TODO: remove old enums when BE DB migration is done
export const AdRestrictions = [
  'ALCOHOL_REFERENCES',
  'BLOOD_REFERENCES',
  'CARTOON_VIOLENCE',
  'DRUG_REFERENCES',
  'ETHICAL_REFERENCES',
  'GAMBLING',
  'NUDITY',
  'POLITICAL_REFERENCES',
  'RELIGIOUS_REFERENCES',
  'SEXUAL_CONTENT',
  'STRONG_LANGUAGE',
  'TOBACCO_REFERENCES',
  'VIOLENCE',
  'WEAPON_REFERENCES',
  'ALCOHOL',
  'GAMBLING_STATE_LOTTERY',
  'GAMBLING_SPORTS',
  'MEDICAL_PHARMA_OC',
  'MEDICAL_PHARMA_PD',
  'MEDICAL_PHARMA_HC_PSA',
  'POLITICS_FEDERAL_ELECTIONS',
  'POLITICS_STATE_LOCAL_ELECTIONS',
]

export const NewAdRestrictions = [
  'ALCOHOL',
  'GAMBLING_STATE_LOTTERY',
  'GAMBLING_SPORTS',
  'MEDICAL_PHARMA_OC',
  'MEDICAL_PHARMA_PD',
  'MEDICAL_PHARMA_HC_PSA',
  'POLITICS_FEDERAL_ELECTIONS',
  'POLITICS_STATE_LOCAL_ELECTIONS',
]

export const AdSpaceProcedural = ['SINGLE_INSTANCE', 'MULTI_INSTANCE_PREFAB']

export const OrgHierarchies = [
  tsTypes.OrgHierarchyEnum.HOLDING,
  tsTypes.OrgHierarchyEnum.SUBSIDIARY,
  tsTypes.OrgHierarchyEnum.STANDALONE,
]

export const OrgVerifyStatus = [
  'UNKNOWN',
  'UNVERIFIED',
  'REJECTED',
  'UNDER_REVIEW',
  'VERIFIED',
]

export const OrgIndustry = [
  'AUTOMOTIVE',
  'B_B',
  'CONSUMER_PACKAGED_GOODS',
  'EDUCATION',
  'ENERGY_AND_UTILITIES',
  'ENTERTAINMENT',
  'FINANCIAL_INSURANCE_SERVICES',
  'GOVERNMENT_NON_PROFIT',
  'HEALTHCARE',
  'RETAIL',
  'TECHNOLOGY',
  'TELECOM',
  'TRAVEL_AND_HOSPITALITY',
  'OTHER',
]

export const AdSpaceTypes = ['IMAGE']

export const AdSpaceMedia = ['DYNAMIC', 'STATIC']

export const AspectRatios = [
  'FOUR_BY_THREE',
  'SIXTEEN_BY_NINE',
  'TWENTY_ONE_BY_NINE',
  'FORTY_BY_NINE',
]

export const GameRatings = [
  'ANY',
  'EARLY_CHILDHOOD',
  'EVERYONE',
  'EVERYONE_TEN_PLUS',
  'TEEN',
  'MATURE',
  'ADULTS_ONLY',
  'RATING_PENDING',
]

export const TimezonesEnum = ['UTC']

export const GameRatingImages = {
  EARLY_CHILDHOOD,
  EVERYONE,
  EVERYONE_TEN_PLUS,
  TEEN,
  MATURE,
  ADULTS_ONLY,
  RATING_PENDING,
}

export const GameGenres = [
  'ANY', // ANY is only available to Advertisements NOT Games/Ad Spaces
  'ACTION',
  'ADVENTURE',
  'CASUAL',
  'INDIE',
  'RACING',
  'ROLE_PLAYING',
  'SIMULATION',
  'STRATEGY',
  'SPORTS',
  'SHOOTER',
]

export const GameReleases = ['EARLY_DEV', 'ALPHA', 'BETA', 'V_1', 'V_1_PLUS']

export const GamePlatforms = ['PC', 'MOBILE', 'CONSOLE']

export const GameOSList = [
  'OSX',
  'WINDOWS',
  'ANDROID',
  'IOS',
  'PLAYSTATION',
  'XBOX',
]

export const Genders = ['MALE', 'FEMALE', 'ALL']

export const OrgTypes = [
  'GAME_PUBLISHER',
  'AGENCY',
  'ADVERTISER',
  'SUPER_ADMIN',
  'THIRD_PARTY',
]

export const LanguageEnum = [
  'ALL',
  'AFRIKAANS',
  'ARABIC',
  'BASQUE',
  'BELARUSIAN',
  'BULGARIAN',
  'CATALAN',
  'CHINESE',
  'CZECH',
  'DANISH',
  'DUTCH',
  'ENGLISH',
  'ESTONIAN',
  'FAROESE',
  'FINNISH',
  'FRENCH',
  'GERMAN',
  'GREEK',
  'HEBREW',
  'ICELANDIC',
  'INDONESIAN',
  'ITALIAN',
  'JAPANESE',
  'KOREAN',
  'LATVIAN',
  'LITHUANIAN',
  'NORWEGIAN',
  'POLISH',
  'PORTUGUESE',
  'ROMANIAN',
  'RUSSIAN',
  'SERBO_CROATIAN',
  'SLOVAK',
  'SLOVENIAN',
  'SPANISH',
  'SWEDISH',
  'THAI',
  'TURKISH',
  'UKRAINIAN',
  'VIETNAMESE',
  'HUNGARIAN',
]

// PAYMENTS
export const PaymentPeriodEnum = ['CREATED', 'DAY', 'MONTH', 'YEAR']

// # Filter by a type
export const PaymentCategoryEnum = ['AD', 'ADSPACE', 'GAME']

// DEPOSIT # Stripe deposit
// WITHDRAWAL # Transferwise withdrawal
// SPEND # Ad view payment
// EARNINGS # Ad view earnings
// ADJUSTMENT # Admin adjustments
export const PaymentTypeEnum = [
  tsTypes.PaymentTypeEnum.DEPOSIT,
  tsTypes.PaymentTypeEnum.WITHDRAWAL,
  tsTypes.PaymentTypeEnum.SPEND,
  tsTypes.PaymentTypeEnum.EARNINGS,
  tsTypes.PaymentTypeEnum.ADJUSTMENT,
]

export const PaymentTypeEnumObj = {
  DEPOSIT: tsTypes.PaymentTypeEnum.DEPOSIT,
  WITHDRAWAL: tsTypes.PaymentTypeEnum.WITHDRAWAL,
  SPEND: tsTypes.PaymentTypeEnum.SPEND,
  EARNINGS: tsTypes.PaymentTypeEnum.EARNINGS,
  ADJUSTMENT: tsTypes.PaymentTypeEnum.ADJUSTMENT,
}

export const PaymentTypeEnumAdvertiser = [
  tsTypes.PaymentTypeEnum.DEPOSIT,
  tsTypes.PaymentTypeEnum.SPEND,
  tsTypes.PaymentTypeEnum.ADJUSTMENT,
]

export const PaymentTypeEnumGamePublisher = [
  tsTypes.PaymentTypeEnum.WITHDRAWAL,
  tsTypes.PaymentTypeEnum.EARNINGS,
  tsTypes.PaymentTypeEnum.ADJUSTMENT,
]

// Column ordering
export const PaymentOrderByEnum = [
  'DATE_CREATED_ASC',
  'DATE_CREATED_DESC',
  'AMOUNT_ASC',
  'AMOUNT_DESC',
]

export const NotificationTypeEnum = ['WARNING', 'SUCCESS', 'ERROR', 'INFO']

export const DateIntervals = ['DAILY', 'WEEKLY', 'MONTHLY']

export const DashboardPeriodIntervalsEnum = [
  'CUSTOM',
  'TODAY',
  'LAST_3_DAYS',
  'LAST_7_DAYS',
  'LAST_14_DAYS',
  'LAST_30_DAYS',
  'LAST_3_MONTHS',
  'LAST_6_MONTHS',
]

export const PeriodIntervalsEnum = [
  'CUSTOM',
  'LAST_DAY',
  'LAST_7_DAYS',
  'LAST_30_DAYS',
  'LAST_2_MONTHS',
  'LAST_3_MONTHS',
  'LAST_4_MONTHS',
  'LAST_5_MONTHS',
  'LAST_6_MONTHS',
  'LAST_7_MONTHS',
  'LAST_8_MONTHS',
  'LAST_9_MONTHS',
  'LAST_10_MONTHS',
  'LAST_11_MONTHS',
  'LAST_12_MONTHS',
  'LAST_13_MONTHS',
]

export const ReportPlatformEnum = [
  'IOS',
  'OSX',
  'ANDROID',
  'WINDOWS',
  'UNKNOWN',
]

export const MetricsEnum = [
  'IMPRESSION_COUNT',
  'VIEWABLE_IMPRESSION_COUNT',
  'REVENUE',
]

export const DimensionsEnum = [
  'COUNTRY',
  'GAME_TITLE',
  'ORGANIZATION',
  'PLATFORM',
  'PLAYER_REGION',
]

export const AdBidTypeEnum = ['CPI']

export const TimeZones = ['GMT +10']

export const ReportingTimezones = ['UTC']

export const VerifyItemEnum = ['AD', 'GAME', 'ORGANIZATION']

export const TitleTypeEnum = [
  'AD',
  'GAME',
  'ORG',
  'CLIENT',
  'MAGNITE_SEAT_ID',
  'MAGNITE_DEAL_ID',
  'REPORT',
]

export const StatusCodeEnum = ['AV100', 'AV101', 'AV200', 'AV400']

export const ThirdPartyTypes = [
  'AFFILIATE',
  'AGGREGATOR',
  'DATA_PROVIDER',
  'GAME_ENGINE',
  'OTHER',
]

export const DayOfTheWeekEnum = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
]

export const PeriodTypeEnum = [
  'CUSTOM',
  'LAST_DAY',
  'LAST_7_DAYS',
  'LAST_30_DAYS',
  'LAST_2_MONTHS',
  'LAST_3_MONTHS',
  'LAST_4_MONTHS',
  'LAST_5_MONTHS',
  'LAST_6_MONTHS',
  'LAST_7_MONTHS',
  'LAST_8_MONTHS',
  'LAST_9_MONTHS',
  'LAST_10_MONTHS',
  'LAST_11_MONTHS',
  'LAST_12_MONTHS',
  'LAST_13_MONTHS',
]

export const CustomerTypeEnum = ['DEMAND', 'SUPPLY']

export const RecurrenceTypeEnum = [
  'NO_REPEAT',
  'DAILY',
  'WEEKLY',
  'MONTHLY_FIRST',
  'MONTHLY_LAST',
]
